import React from 'react'
import { PUBLICATIONS } from '../../components/constant'
import Layout from '../../components/layout'
import PageStructure from '../../components/pagestructure'
import SEO from '../../components/seo'
import IMGResearch from '../../images/new/rpaper.jpg'
const ResearchPapers = () => {
  return (
    <div>
      <Layout>
        <SEO title="Research Papers" />
        <PageStructure
          title="Research Papers"
          desc="TESTIG"
          image={IMGResearch}
          mode={PUBLICATIONS}
        />
      </Layout>
    </div>
  )
}

export default ResearchPapers
